import HeaderComponent from '../components/_header';
import FooterComponent from '../components/_footer';
import { useEffect, useRef, useState } from 'react';
import Image from '../components/_image';
import { Link, useNavigate } from 'react-router-dom';
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { Helmet } from 'react-helmet';
import ImagesData from '../LocalDB/ImageData.json';

const HomePage = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [balloonSize, setBalloonSize] = useState(0);
    const [isBallonShown, setIsBallonShown] = useState(true);
    const balloonRef = useRef(null);

    const openGraphImage = ImagesData.images.find(image => image.id === 'shaadowOpenGraph');


    const navigate = useNavigate();
    const gotoNotifyPage = () => {
        navigate("/join/notify-me");
    };



    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.pageYOffset || document.documentElement.scrollTop;
            setScrollPosition(scrollY);

            // Calculate size based on scroll position (after y-axis minimum threshold)
            if (scrollY >= 4.5 * 7) { // Adjust for pixel value based on rem conversion
                const exponentialIncrease = Math.pow(scrollY - 4.5 * 7, 0.5); // Adjust exponent for desired curve
                setBalloonSize(exponentialIncrease);

                // Check if balloon reaches right edge
                if (balloonRef.current && scrollY >= 4.5 * 7 && balloonRef.current.getBoundingClientRect().top >= 141) {
                    triggerBalloonBurstAnimation(balloonRef.current); // Call animation function
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const triggerBalloonBurstAnimation = (balloonElement) => {
        balloonElement.classList.add('balloon-burst');
        setIsBallonShown(false);
    };


    return (
        <>
            <Helmet>
                <title>Shaadow Platforms: Coming soon</title>
                <meta name="description" content="Shaadow: India's all-in-one app! Chat, call, share & more with privacy & security. Seamless file transfer, group chats, ad-free music (Tunes) & custom browser homepage. Experience the future of social, one app at a time." />

                {/* //for Title */}
                <meta property="og:title" content="Shaadow Platforms" />
                <meta itemprop="name" content="Shaadow Platforms" />
                <meta name="twitter:title" content="Shaadow Platforms" />

                {/* //for Description */}
                <meta property="og:description" content="Shaadow: India's all-in-one app! Chat, call, share & more with privacy & security. Seamless file transfer, group chats, ad-free music (Tunes) & custom browser homepage. Experience the future of social, one app at a time." />
                <meta itemprop="description" content="Shaadow: India's all-in-one app! Chat, call, share & more with privacy & security. Seamless file transfer, group chats, ad-free music (Tunes) & custom browser homepage. Experience the future of social, one app at a time." />
                <meta name="twitter:description" content="Shaadow: India's all-in-one app! Chat, call, share & more with privacy & security. Seamless file transfer, group chats, ad-free music (Tunes) & custom browser homepage. Experience the future of social, one app at a time." />

                {/* //for Image */}
                <meta property="og:image" content={openGraphImage.imgUrl} />
                <meta itemprop="image" content={openGraphImage.imgUrl} />
                <meta name="twitter:image" content={openGraphImage.imgUrl} />

                {/* //others */}
                <link rel="canonical" href={window.location.href} />
                <meta property="og:url" content={window.location.href} />
                <meta property="twitter:url" content={window.location.href} />

                <meta property="og:type" content="website" />
            </Helmet>


            <section className="relative bg-black">
                {isBallonShown && (<>
                    <div ref={balloonRef}
                        style={{
                            position: 'fixed',
                            left: scrollPosition * 0.25,
                            top: scrollPosition * 0.1 + 4.5 * 7,
                            width: balloonSize * 8 + 'px',
                            height: balloonSize * 8 + 'px',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(255, 255, 255, 0.04)',
                            boxShadow: 'rgba(255, 255, 255, 0.25) 0px 0px 22px 0px inset',
                            zIndex: 22
                        }}
                        className="balloon"
                    >
                        <p className={`y74884nuf_hh z-10 rounded-full`} style={{ width: '100%', height: '100%' }}></p>
                    </div>
                </>)}


                <HeaderComponent />

                <section className="w-full overflow-x-hidden" id="landing">
                    <div className="w-full h-full flex justify-center items-center flex-col pt-56 pb-32 max-[640px]:px-6 relative">
                        <div className="max-[640px]:w-full border border-dashed border-gray-700 sm:border-t-0 flex justify-center items-center flex-col relative aji3b893">
                            <div className="sm:w-max max-[640px]:pt-4 w-full border border-dashed border-gray-700 border-b-0 relative ie93jBuejmm border-x-0 border-t-0">
                                <div className="sm:px-6 px-4 sm:py-4 py-6 text-center">
                                    <AnimatedOnScroll animationIn="bounceInLeft">
                                        <h1 className="max-[6640px]:text-4xl lg:text-8xl md:text-5xl sm:text-5xl text-white font-medium sm:w-[58.7rem] w-full tracking-wide special-font">India ka social media</h1>
                                    </AnimatedOnScroll>
                                </div>
                            </div>

                            <div className="sm:w-max w-full border border-dashed border-gray-700 relative b83jHuje rounded-full mt-3">
                                <div className="sm:w-[55rem] w-full px-8 py-10 text-center">
                                    <AnimatedOnScroll animationIn="bounceInRight">
                                        <p className="sm:text-lg text-base text-gray-600 font-medium tracking-wide">From Socializing to Building, We've Got You Covered!</p>
                                        <p className="sm:text-base text-sm text-gray-700 font-normal tracking-wide mt-2">~ Made by Shaadow</p>
                                    </AnimatedOnScroll>
                                </div>
                            </div>

                            <div className="w-full relative b83jHuje flex justify-center items-center">
                                <div className="sm:w-max w-full sm:px-36 py-10 mt-5 border border-b-0 border-dashed border-gray-700 max-[640px]:border-x-0 rounded-tl-3xl rounded-tr-3xl text-center grid sm:grid-cols-2 max-[768px]:grid-rows-2 sm:grid-flow-row max-[640px]:justify-center gap-4 grid-flow-col">
                                    <a target='_blank' rel='noreferrer' href={"https://tunes.shaadow.in?from=shaadow.in&sc=landing"} className="w-[16rem] text-sm sm:text-base transition border border-zinc-500 text-zinc-500 font-semibold hover:bg-zinc-900 hover:border-zinc-300 hover:text-gray-300 tracking-wide flex justify-center items-center">
                                        Download Tunes
                                    </a>

                                    <button className="w-[16rem] bt4mej93NuwjHue" onClick={gotoNotifyPage}>
                                        <span className="text">Get Notified</span>
                                        <span>For Shaadow</span>
                                    </button>
                                </div>
                            </div>

                            <p className="m92nuehhu3"></p> {/* top design */}
                            <p className="b83hueu83n"></p> {/* left design */}
                            <p className="c782bju8en"></p> {/* right design */}
                        </div>

                        <p className="qewbue87Gyeu top-[4rem] left-[1rem] sm:left-[8rem]"></p>
                        <p className="wy83m83Heue sm:top-[7rem] top-[20rem] left-[5rem] sm:left-[15rem]"></p>
                        <p className="r66ftr5yu top-[27rem] right-[1rem] sm:right-[5rem]"></p>
                        <p className="t8794njie99 top-[35rem] sm:top-[32rem] right-[2rem] sm:right-[3rem]"></p>
                        <p className="absolute top-[35rem] left-[1rem] sm:left-[10rem] w-[2.8rem] h-[2.8rem] rounded-full bg-zinc-800/25 animate-bounce"></p>
                        <p className="absolute top-[4rem] sm:top-[10rem] right-[2.5rem] sm:right-[4rem] w-[4.8rem] h-[4.8rem] rounded-lg bg-zinc-800/25 animate-[spinSlow_5s_ease-in-out_infinite]"></p>
                    </div>
                </section>


                <section className="w-full pb-14 pt-4 px-6 sm:px-20">
                    <p className="font-medium w-full text-center text-gray-200 text-3xl py-8 special-font capitalize">Why Shaadow?</p>

                    <div className="grid sm:grid-flow-row-dense sm:grid-cols-3 grid-cols-1 sm:gap-5 gap-4 w-full">
                        <AnimatedOnScroll animationIn="fadeInLeft">
                            <div className="bg-gradient-to-r from-zinc-800 to-zinc-950 w-auto rounded-xl border border-zinc-700 cursor-default hover:scale-105 transition-all ease-linear duration-200">
                                <div className="w-full h-full flex justify-start items-start flex-col p-4 sm:p-8">
                                    <h2 className="text-zinc-50 font-medium text-lg tracking-wide mb-1">P2P Media Sharing</h2>
                                    <p className="text-zinc-500 font-normal text-base leading-5">Share files directly with friends, no servers involved</p>
                                </div>
                            </div>
                        </AnimatedOnScroll>
                        <AnimatedOnScroll animationIn="fadeInLeft">
                            <div className={`bg-gradient-to-r from-zinc-950 ${width > 768 ? 'via-zinc-800 to-zinc-950' : 'to-zinc-800'} w-auto rounded-xl border border-zinc-700 cursor-default hover:scale-105 transition-all ease-linear duration-200`}>
                                <div className="w-full h-full flex justify-start items-start flex-col p-4 sm:p-8">
                                    <h2 className="text-zinc-50 font-medium text-lg tracking-wide mb-1">Interactive Chat Groups</h2>
                                    <p className="text-zinc-500 font-normal text-base leading-5">Connect and engage with communities in real-time</p>
                                </div>
                            </div>
                        </AnimatedOnScroll>
                        <AnimatedOnScroll animationIn="fadeInLeft">
                            <div className="sm:bg-gradient-to-l bg-gradient-to-r from-zinc-800 to-zinc-950 w-auto rounded-xl border border-zinc-700 cursor-default hover:scale-105 transition-all ease-linear duration-200">
                                <div className="w-full h-full flex justify-start items-start flex-col p-4 sm:p-8">
                                    <h2 className="text-zinc-50 font-medium text-lg tracking-wide mb-1">Privacy-Focused Experience</h2>
                                    <p className="text-zinc-500 font-normal text-base leading-5">Built with Indian users in mind, prioritize your data control</p>
                                </div>
                            </div>
                        </AnimatedOnScroll>

                        <AnimatedOnScroll animationIn="fadeInRight">
                            <div className="sm:bg-gradient-to-r bg-gradient-to-l from-zinc-800 to-zinc-950 w-auto rounded-xl border border-zinc-700 cursor-default hover:scale-105 transition-all ease-linear duration-200">
                                <div className="w-full h-full flex justify-start items-start flex-col p-4 sm:p-8">
                                    <h2 className="text-zinc-50 font-medium text-lg tracking-wide mb-1">Seamless Device Sync</h2>
                                    <p className="text-zinc-500 font-normal text-base leading-5">Access your content and conversations across all your devices</p>
                                </div>
                            </div>
                        </AnimatedOnScroll>
                        <AnimatedOnScroll animationIn="fadeInRight">
                            <div className={`bg-gradient-to-l from-zinc-950 ${width > 768 ? 'via-zinc-800 to-zinc-950' : 'to-zinc-800'} w-auto rounded-xl border border-zinc-700 cursor-default hover:scale-105 transition-all ease-linear duration-200`}>
                                <div className="w-full h-full flex justify-start items-start flex-col p-4 sm:p-8">
                                    <h2 className="text-zinc-50 font-medium text-lg tracking-wide mb-1">Empowering Creators & Brands</h2>
                                    <p className="text-zinc-500 font-normal text-base leading-5">Dedicated tools and support for creators and businesses to thrive</p>
                                </div>
                            </div>
                        </AnimatedOnScroll>
                        <AnimatedOnScroll animationIn="fadeInRight">
                            <div className="bg-gradient-to-l from-zinc-800 to-zinc-950 w-auto rounded-xl border border-zinc-700 cursor-default hover:scale-105 transition-all ease-linear duration-200">
                                <div className="w-full h-full flex justify-start items-start flex-col p-4 sm:p-8">
                                    <h2 className="text-zinc-50 font-medium text-lg tracking-wide mb-1">Ad-Free Experience</h2>
                                    <p className="text-zinc-500 font-normal text-base leading-5">Enjoy an uninterrupted and distraction-free social experience</p>
                                </div>
                            </div>
                        </AnimatedOnScroll>
                    </div>
                </section>


                <section className="w-full sm:px-20 px-6 pb-16 ">
                    <div className="w-full h-auto overflow-hidden QBbu83jejij83">
                        <div className="relative Vheb73njd w-full h-auto overflow-hidden rounded-3xl">
                            <div className="w-full h-full absolute top-0 left-0 max-[768px]:flex max-[768px]:items-start">
                                <AnimatedOnScroll animationIn="rollIn">
                                    <div className="relative Z83jby783b lg:w-2/4 md:w-3/4 max-[768px]:w-full sm:pl-14 max-[768px]:px-6 sm:py-12 max-[768px]:pt-6 flex justify-center items-start flex-col">
                                        <h2 className="w-full text-4xl min-[768px]:text-5xl max-[640px]:text-3xl text-gray-100 font-bold special-font tracking-wide">Speak, Share Freely!</h2>
                                        <p className={`sm:w-3/4 w-full text-base min-[768px]:text-lg max-[640px]:text-sm text-gray-100 sm:mt-6 md:mt-5 mt-4 ${width < 768 && 'lined-paragraph'}`}>
                                            <b>Replace boring apps!</b>
                                            {" "}Join a vibrant Indian social hub for all your fun:
                                            {" "}<em>share freely</em>, <em>explore endlessly</em>, and <em>connect with your people</em>.
                                        </p>

                                        <button
                                            onClick={gotoNotifyPage}
                                            className="w-[10rem] bt4mej93NuwjHue mt-10 min-[768px]:mt-10 max-[640px]:mt-8 rounded-bl-lg text-base rounded-tr-lg">
                                            <span className="text">Get Notified</span>
                                            <span>For Shaadow</span>
                                        </button>
                                    </div>
                                </AnimatedOnScroll>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="w-full py-4 bg-white">
                    <div className="w-full h-full flex justify-center items-center flex-col px-6 sm:px-20">
                        <p className="font-medium w-full text-center text-gray-950 text-3xl py-8 special-font capitalize">Our best Product 🔥</p>

                        <div className="w-full h-full flex justify-between items-start flex-wrap-reverse my-12">
                            <div className="sm:w-2/4 w-full flex justify-center items-start flex-col overflow-hidden">
                                <AnimatedOnScroll animationIn="zoomInLeft">
                                    <h2 className="text-3xl font-semibold text-black">Introducing Tunes 🎧</h2>
                                    <p className="text-base font-medium sm:text-sm text-zinc-800 mt-1">A music app from Shaadow</p>

                                    <p className="text-lg sm:text-base text-zinc-800 mt-6 mb-12 lined-paragraph">Tunes by Shaadow is your ultimate music companion. With a vast library of songs, personalized playlists, and a sleek user interface, Tunes revolutionizes your music listening experience.

                                        <br /><br />Discover and enjoy all types of music, from chart-toppers to timeless classics. No login or signup is required, so you can dive straight into the melodies.

                                        <br /><br />Feel the rhythm and explore the world of music with Tunes!
                                    </p>

                                    <div className="flex items-center w-full">
                                        <Link to={"https://tunes.shaadow.in?url_ref=landing&&url_source=shaadow.in"}
                                            className="flex items-center px-8 py-2 border border-black hover:bg-black text-black hover:text-white transition-all delay-150 ease-linear">
                                            Know More
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 ml-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                            </svg>
                                        </Link>
                                    </div>
                                </AnimatedOnScroll>
                            </div>

                            <div className="sm:w-2/4 w-full h-[28rem] flex justify-center items-center max-[768px]:mb-12 overflow-x-clip">
                                <div className="w-full h-full relative">
                                    <AnimatedOnScroll
                                        animationIn="bounceInRight"
                                        className="absolute sm:top-[-18%] sm:right-[8%] right-[-2%] top-[-12%]"
                                    >
                                        <Image
                                            imgAlt={"Tunes-ScreenShot"}
                                            imgClass={"sm:w-[18rem] max-[768px]:w-[15rem] rotate-12 rounded-md"}
                                            imgSrc={"https://i.ibb.co/kMFbjvr/tunes-mockups-img1-bu83jd.png"} />
                                    </AnimatedOnScroll>

                                    <AnimatedOnScroll
                                        animationIn="bounceInLeft"
                                        className="absolute sm:top-[-8%] sm:left-[8%] left-[-2%] top-[-4%]"
                                    >
                                        <Image
                                            imgAlt={"Tunes-ScreenShot"}
                                            imgClass={"sm:w-[18rem] max-[768px]:w-[15rem] rotate-12 rounded-[3rem]"}
                                            imgSrc={"https://i.ibb.co/sjBsyPM/tunes-mockups-img2-n903jd.png"} />
                                    </AnimatedOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="w-full bg-[#121212] sm:py-8">
                    <div className="w-full sm:px-20 px-6 py-14 flex justify-center items-center max-[768px]:flex-col">
                        <div className="sm:w-3/4 w-full h-full max-[768px]:text-center">
                            <p className="text-slate-100 text-3xl font-medium tracking-wide">Need a website?</p>
                            <p className="text-slate-400">Personal or business, we've got you covered. Tell us your ideas, fill our form, and let's make it happen!</p>
                        </div>

                        <div className="sm:w-1/4 w-full h-full flex justify-center items-center max-[768px]:mt-14">
                            <a target='_blank' rel='noreferrer' href={"https://forms.gle/ZtEb2x1hq2CMmcj58"} className="text-lg font-medium px-8 py-2 bg-white text-zinc-900 rounded-md hover:bg-transparent hover:border hover:border-solid hover:border-white hover:text-white transition ease-in-out duration-300">Get a Quote</a>
                        </div>
                    </div>
                </section>

                <FooterComponent />
            </section>
        </>
    )
}
export default HomePage;