import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';

// all pages navigator
import HomePage from "./pages/HomePage";
const NotFoundPage = lazy(() => wait(1000).then(() => import('./pages/NotFoundPage')));
const ContactPage = lazy(() => wait(1000).then(() => import('./pages/ContactPage')));
const JoinPage = lazy(() => wait(1000).then(() => import('./pages/JoinPage')));
const FAQsPage = lazy(() => wait(1000).then(() => import('./pages/FAQsPage')));
const TermsPage = lazy(() => wait(1000).then(() => import('./pages/lg/TermsPage')));
const PrivacyPage = lazy(() => wait(1000).then(() => import('./pages/lg/PrivacyPage')));
const CookiesPage = lazy(() => wait(1000).then(() => import('./pages/lg/CookiesPage')));
const BlogHome = lazy(() => wait(1000).then(() => import('./pages/bo/BlogHome')));
const BlogView = lazy(() => wait(1000).then(() => import('./pages/bo/BlogView')));
const BlogFilter = lazy(() => wait(1000).then(() => import('./pages/bo/BlogFilter')));
const CreateBlog = lazy(() => wait(1000).then(() => import('./pages/ds/CreateBlog')));
const AdminLogin = lazy(() => wait(1000).then(() => import('./pages/ds/AdminLogin')));
const Dashboard = lazy(() => wait(1000).then(() => import('./pages/ds/Dashboard')));
const ViewData = lazy(() => wait(1000).then(() => import('./pages/ds/ViewData')));
const Team = lazy(() => wait(1000).then(() => import('./pages/ds/Team')));


const App = () => {
  // Define routes array
  const routes = [
    { path: '/', element: <HomePage />, exact: true, uKey: 'index' },
    { path: '/home', element: <HomePage />, exact: true, uKey: 'home' },
    { path: '/contact', element: <ContactPage />, exact: true, uKey: 'about' },
    { path: '/join/notify-me', element: <JoinPage />, exact: true, uKey: 'notify-me' },
    { path: '/faqs', element: <FAQsPage />, exact: true, uKey: 'faqs' },

    { path: '/legal/terms-of-use', element: <TermsPage />, exact: true, uKey: 'terms' },
    { path: '/legal/privacy-policy', element: <PrivacyPage />, exact: true, uKey: 'privacy' },
    { path: '/legal/cookies-policy', element: <CookiesPage />, exact: true, uKey: 'cookies' },

    { path: '/blog/', element: <BlogHome />, exact: true, uKey: 'blog-home' },

    { path: '/blog/product/:productCategory', element: <BlogFilter productCategory={":productCategory"} />, uKey: 'blog-productCategory' },
    { path: '/blog/category/:blogType', element: <BlogFilter blogType={":blogType"} />, uKey: 'blog-blogType' },

    { path: '/blog/:productCategory/:blogType/:blogId', element: <BlogView productCategory={":productCategory"} blogType={":blogType"} blogId={":blogId"} />, uKey: 'blog-details' },

    { path: '/dashboard/overview', element: <Dashboard />, exact: true, uKey: 'dashboard' },
    { path: '/dashboard/view/data', element: <ViewData />, exact: true, uKey: 'user-data' },
    { path: '/dashboard/team', element: <Team />, exact: true, uKey: 'team' },
    { path: '/dashboard/login', element: <AdminLogin />, exact: true, uKey: 'dashboard' },
    { path: '/dashboard/blog/create', element: <CreateBlog />, exact: true, uKey: 'dashboard' },

    { path: '*', element: <NotFoundPage />, uKey: 'not-found-404' }
  ];
  return (
    <Router basename="/">
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.uKey} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Suspense>
    </Router>
  );
}


function wait(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}
export default App;
