import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import HeaderLinks from "../LocalDB/NavigationLinks.json";
import { useEffect, useState } from "react";
import ShaadowLogo from './_shaadowLogo';
import ImagesData from '../LocalDB/ImageData.json';



const HeaderComponent = () => {
    const location = useLocation();

    const [showPrdct, setShowPrdct] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);

    const homePageLogo = ImagesData.images.find(image => image.id === 'homePageLogo');
    const tunesLogo = ImagesData.images.find(image => image.id === 'tunesLogo');
    const ShaadowOnlyLogoIcon = ImagesData.images.find(image => image.id === 'ShaadowOnlyLogoIcon');


    const navigate = useNavigate();
    const gotoNotifyPage = () => {
        navigate("/join/notify-me");
    };

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleScroll = () => setScrollPosition(window.scrollY);
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    const middenData = [10754, 10755, 10756]; // Define allowed IDs

    const headerMiddenLinks = HeaderLinks.normal.filter((midden) =>
        middenData.includes(midden.id)
    );


    const handleMobileNavigation = () => {
        setShowMobileMenu(!showMobileMenu);

        // Toggle overflow-y based on the state
        document.body.style.overflowY = showMobileMenu ? 'auto' : 'hidden';
    }

    return (
        <header className={`h-20 ${scrollPosition >= 2 ? "bg-zinc-950/[.99]" : "bg-black"} flex justify-center items-center w-full bu93e fixed top-0 left-0 z-40`}>
            <div className="h-full w-full flex justify-center items-center">
                <div className={`px-4 lg:px-6 relative w-full h-full max-w-screen-xl flex ${width <= 1023 ? 'justify-center flex-col' : 'justify-between flex-row'} items-center`}>

                    {/* mobile navbar starts */}
                    {width < 750 && (
                        <>
                            <div className="mobile-navbar h-full max-[768px]:py-3 flex flex-wrap justify-between items-center w-full z-10">

                                <Link to="/home" className=" flex items-center justify-center p-1 h-12 rounded-md -mt-2">
                                    <ShaadowLogo width={width >= 390 ? "155" : "95"} height={width >= 390 ? "45" : "40"} type={"white"} />
                                </Link>


                                <div className="flex items-center lg:order-2 h-full lg:flex-row lg:space-x-8 lg:mt-0">
                                    <button
                                        onClick={gotoNotifyPage}
                                        className="n29 text-gray-600 hover:bg-zinc-900 hover:text-white font-medium rounded-lg cursor-pointer text-sm px-2 py-1 outline outline-1 outline-gray-500 hover:outline-none uppercase mr-3 flex items-center justify-between max-[410px]:hidden">
                                        <span className="mr-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
                                            </svg>
                                        </span>
                                        <span className="">Get Notified</span>
                                    </button>



                                    <button
                                        type="button"
                                        onClick={handleMobileNavigation}
                                        className="inline-flex items-center p-2 text-sm text-gray-600 focus:text-white hover:text-white rounded-lg lg:hidden focus:outline-none">
                                        <span className="hidden">Open main menu</span>
                                        {showMobileMenu === false ? (
                                            <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                        ) : (
                                            <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        )}
                                    </button>
                                </div>
                            </div>


                            <div className={`${showMobileMenu === false ? 'hidden' : 'show'} justify-between items-center w-full h-full lg:flex lg:w-auto max-[768px]:w-screen max-[768px]:absolute max-[768px]:left-0 max-[768px]:top-[4.5rem] iej2uhHue max-[768px]:backdrop-blur-sm max-[768px]:bg-black/10 max-[768px]:h-screen`}>
                                <ul className={`flex flex-col h-full sm:mt-4 lg:flex-row lg:space-x-8 lg:mt-0 ${scrollPosition >= 2 ? "max-[768px]:bg-zinc-950" : "max-[768px]:bg-black"} h-min py-10`}>
                                    <li
                                        className={`n29 BAjd3u3bhue font-medium py-2 text-right h-100 uppercase cursor-pointer text-lg px-5 ${location.pathname === "/" || location.pathname === "/home" ? 'active' : ''}`}>
                                        <NavLink className={`p-2 bu83nVUw text-gray-600 w-full sm:inline block`} to={"/home"}>Home</NavLink>
                                    </li>

                                    {headerMiddenLinks.map((midden) => (
                                        <li key={midden.id + "b9k"} className={`n29 BAjd3u3bhue font-medium py-2 text-right h-100 uppercase cursor-pointer text-lg px-5 ${midden.link === location.pathname ? 'active' : ''}`}>
                                            <NavLink className={`p-2 bu83nVUw text-gray-600 w-full sm:inline block`} to={midden.link}>{midden.label}</NavLink>
                                        </li>
                                    ))}

                                    <li
                                        className={`max-[410px]:block min-[411px]:hidden n29 BAjd3u3bhue font-medium py-2 text-right h-100 uppercase cursor-pointer text-lg px-5 ${location.pathname === "/join/notify-me" && 'active'}`}>
                                        <NavLink className={`p-2 bu83nVUw text-gray-600 w-full sm:inline block`} to={"/join/notify-me"}>Get Notified</NavLink>
                                    </li>

                                    <li className="vy8ggjAWfg relative flex justify-end items-center px-5 py-2">
                                        <button
                                            onClick={() => { setShowPrdct(!showPrdct) }}
                                            className={`p-2 n29 w-full bh8vAwegFY86 text-white font-medium rounded-lg text-lg cursor-pointer text-right ${showPrdct && 'active'}`}
                                            type="button">
                                            <span className="text-gray-600 uppercase">PRODUCTS</span>
                                        </button>

                                        {/* <!-- Products Dropdown menu --> */}
                                        {showPrdct && (<>
                                            <div className="b77x5Ty sm:w-96 overflow-hidden sm:absolute sm:top-full sm:mt-1 sm:right-0 z-10 shadow sm:rounded-lg p-4 max-[640px]:px-6 sm:border max-[640px]:border-t border-[#333] transition ease-in-out delay-150 max-[640px]:fixed max-[640px]:top-0 max-[640px]:left-0 max-[640px]:w-full max-[640px]:h-full">
                                                <div className="w-full h-full max-[640px]:pt-12 sm:pt-0">
                                                    <div className="grid grid-rows-3 grid-flow-col gap-4 w-full">
                                                        <div>
                                                            <div className="cursor-default grid gap-0 grid-cols-4 w-full border border-solid border-[#333] rounded-lg overflow-hidden b7hHuwjeH72">
                                                                <div className="flex justify-center items-center w-16 h-16 col-span-1 p-2 c6fAe5t overflow-hidden">
                                                                    <img className="w-auto h-auto rounded-lg bg-black" src={ShaadowOnlyLogoIcon.imgUrl} alt={ShaadowOnlyLogoIcon.imgAlt} />
                                                                </div>
                                                                <div className="flex items-start justify-center flex-col w-full h-full col-span-3 -ml-3 max-[640px]:-ml-4">
                                                                    <p className="mb-0 font-medium text-[#f9f9f9] n29 text-base leading-5">Shaadow App</p>
                                                                    <p className="mb-0 font-normal text-[#f9f9f99d] n29 text-sm">Coming soon!</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <Link to={"https://tunes.shaadow.in/?url_ref=header&url_source=shaadow.in"}>
                                                            <div className="cursor-pointer grid gap-0 grid-cols-4 w-full border border-solid border-[#333] rounded-lg overflow-hidden b7hHuwjeH72">
                                                                <div className="flex justify-center items-center w-16 h-16 col-span-1 p-2 c6fAe5t overflow-hidden">
                                                                    <img className="w-auto h-auto rounded-lg bg-black" src={tunesLogo.imgUrl} alt="Tunes-Logo" />
                                                                </div>
                                                                <div className="flex items-start justify-center flex-col w-full h-full col-span-3 -ml-3 max-[640px]:-ml-4">
                                                                    <p className="mb-0 font-medium text-[#f9f9f9] n29 text-base leading-5">Tunes By Shaadow</p>
                                                                    <p className="mb-0 font-normal text-[#f9f9f99d] n29 text-sm">Your favorite music buddy!</p>
                                                                </div>
                                                            </div>
                                                        </Link>

                                                        <Link to={"https://hp.shaadow.in/?url_ref=header&url_source=shaadow.in"}>
                                                            <div className="cursor-pointer grid gap-0 grid-cols-4 w-full border border-solid border-[#333] rounded-lg overflow-hidden b7hHuwjeH72">
                                                                <div className="flex justify-center items-center w-16 h-16 col-span-1 p-2 c6fAe5t overflow-hidden">
                                                                    <img className="w-auto h-auto rounded-lg" src={homePageLogo.imgUrl} alt="Tunes-Logo" />
                                                                </div>
                                                                <div className="flex items-start justify-center flex-col w-full h-full col-span-3 -ml-3 max-[640px]:-ml-4">
                                                                    <p className="mb-0 font-medium text-[#f9f9f9] n29 text-base leading-5">Home-Page</p>
                                                                    <p className="mb-0 font-normal text-[#f9f9f99d] n29 text-sm">A searching tool 4 browser!</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div
                                                    className="max-[640px]:fixed max-[640px]:top-3 max-[640px]:left-0 max-[640px]:w-full max-[640px]:h-10 max-[640px]:flex max-[640px]:justify-between max-[640px]:items-center sm:hidden">
                                                    <p className="text-base text-gray-100 mb-0 ml-6">Our Products</p>
                                                    <button
                                                        className="w-10 h-10 flex justify-center items-center mr-4"
                                                        onClick={() => { setShowPrdct(!showPrdct) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#f9f9f9" className="w-6 h-6">
                                                            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </>)}
                                    </li>
                                </ul>
                            </div>
                        </>
                    )}
                    {/* mobile navbar ends */}



                    {/* tablet navbar starts */}
                    {width >= 750 && width <= 1023 && (
                        <>
                            <div className="tablet-navbar w-full h-full">
                                <div className="max-[1023px]:py-4 flex justify-between items-center w-full z-10">

                                    <Link to="/home" className=" flex items-center justify-center p-1 h-12 rounded-md -mt-2">
                                        <ShaadowLogo width={"155"} height={"45"} type={"white"} />
                                    </Link>


                                    <div className="flex items-center lg:order-2 h-full lg:flex-row lg:space-x-8 lg:mt-0">
                                        <div className="flex justify-center items-center">
                                            {/* notify-me/ download button */}
                                            <button
                                                onClick={gotoNotifyPage}
                                                className="n29 text-gray-600 hover:sm:bg-zinc-800 hover:text-white font-medium rounded-lg cursor-pointer text-sm px-2 py-1 sm:outline sm:outline-1 sm:outline-gray-500 hover:sm:outline-none uppercase ml-3 sm:ml-0 flex items-center justify-between">
                                                <span className="mr-0 sm:mr-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
                                                    </svg>
                                                </span>
                                                <span className="">Get Notified</span>
                                            </button>

                                            {/* products button */}
                                            <div className="ml-3 vy8ggjAWfg relative h-full flex justify-center items-center">
                                                <button
                                                    onClick={() => { setShowPrdct(!showPrdct) }}
                                                    className={`n29 bh8vAwegFY86 text-white font-medium rounded-lg text-sm py-2.5 cursor-pointer text-center inline-flex items-center ${showPrdct && 'active'}`}
                                                    type="button">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" className={`w-6 h-6 fill-gray-600`}><path d="M240-160q-33 0-56.5-23.5T160-240q0-33 23.5-56.5T240-320q33 0 56.5 23.5T320-240q0 33-23.5 56.5T240-160Zm240 0q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm240 0q-33 0-56.5-23.5T640-240q0-33 23.5-56.5T720-320q33 0 56.5 23.5T800-240q0 33-23.5 56.5T720-160ZM240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400ZM240-640q-33 0-56.5-23.5T160-720q0-33 23.5-56.5T240-800q33 0 56.5 23.5T320-720q0 33-23.5 56.5T240-640Zm240 0q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Zm240 0q-33 0-56.5-23.5T640-720q0-33 23.5-56.5T720-800q33 0 56.5 23.5T800-720q0 33-23.5 56.5T720-640Z" /></svg>
                                                    <span className="ml-1 text-gray-600 uppercase hidden sm:block">PRODUCTS</span>
                                                </button>

                                                {/* <!-- Products Dropdown menu --> */}
                                                {showPrdct && (<>
                                                    <div className="b77x5Ty sm:w-96 overflow-hidden sm:absolute sm:top-full sm:mt-1 sm:right-0 z-10 shadow sm:rounded-lg p-4 sm:border sm:border-solid sm:border-[#333] transition ease-in-out delay-150">
                                                        <div className="w-full h-full sm:pt-0">
                                                            <div className="grid grid-rows-3 grid-flow-col gap-4 w-full">
                                                                <div>
                                                                    <div className="cursor-default grid gap-0 grid-cols-4 w-full border border-solid border-[#333] rounded-lg overflow-hidden b7hHuwjeH72">
                                                                        <div className="flex justify-center items-center w-16 h-16 col-span-1 p-2 c6fAe5t overflow-hidden">
                                                                            <img className="w-auto h-auto rounded-lg bg-black" src={ShaadowOnlyLogoIcon.imgUrl} alt={ShaadowOnlyLogoIcon.imgAlt} />
                                                                        </div>
                                                                        <div className="flex items-start justify-center flex-col w-full h-full col-span-3 -ml-3">
                                                                            <p className="mb-0 font-medium text-[#f9f9f9] n29 text-base leading-5">Shaadow App</p>
                                                                            <p className="mb-0 font-normal text-[#f9f9f99d] n29 text-sm">Coming soon!</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <Link to={"https://tunes.shaadow.in/?url_ref=header&url_source=shaadow.in"}>
                                                                    <div className="cursor-pointer grid gap-0 grid-cols-4 w-full border border-solid border-[#333] rounded-lg overflow-hidden b7hHuwjeH72">
                                                                        <div className="flex justify-center items-center w-16 h-16 col-span-1 p-2 c6fAe5t overflow-hidden">
                                                                            <img className="w-auto h-auto rounded-lg bg-black" src={tunesLogo.imgUrl} alt="Tunes-Logo" />
                                                                        </div>
                                                                        <div className="flex items-start justify-center flex-col w-full h-full col-span-3 -ml-3">
                                                                            <p className="mb-0 font-medium text-[#f9f9f9] n29 text-base leading-5">Tunes By Shaadow</p>
                                                                            <p className="mb-0 font-normal text-[#f9f9f99d] n29 text-sm">Your favorite music buddy!</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>

                                                                <Link to={"https://hp.shaadow.in/?url_ref=header&url_source=shaadow.in"}>
                                                                    <div className="cursor-pointer grid gap-0 grid-cols-4 w-full border border-solid border-[#333] rounded-lg overflow-hidden b7hHuwjeH72">
                                                                        <div className="flex justify-center items-center w-16 h-16 col-span-1 p-2 c6fAe5t overflow-hidden">
                                                                            <img className="w-auto h-auto rounded-lg" src={homePageLogo.imgUrl} alt="Tunes-Logo" />
                                                                        </div>
                                                                        <div className="flex items-start justify-center flex-col w-full h-full col-span-3 -ml-3">
                                                                            <p className="mb-0 font-medium text-[#f9f9f9] n29 text-base leading-5">Home-Page</p>
                                                                            <p className="mb-0 font-normal text-[#f9f9f99d] n29 text-sm">A searching tool 4 browser!</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="max-[640px]:fixed max-[640px]:top-3 max-[640px]:left-0 max-[640px]:w-full max-[640px]:h-10 max-[640px]:flex max-[640px]:justify-between max-[640px]:items-center sm:hidden">
                                                            <p className="text-base text-gray-100 mb-0 ml-6">Our Products</p>
                                                            <button
                                                                className="w-10 h-10 flex justify-center items-center"
                                                                onClick={() => { setShowPrdct(!showPrdct) }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#f9f9f9" className="w-6 h-6">
                                                                    <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>)}
                                            </div>
                                        </div>



                                        <button
                                            type="button"
                                            onClick={handleMobileNavigation}
                                            className="inline-flex items-center p-2 text-sm text-gray-600 focus:text-white hover:text-white rounded-lg lg:hidden focus:outline-none">
                                            <span className="hidden">Open main menu</span>
                                            {showMobileMenu === false ? (
                                                <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                            ) : (
                                                <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            )}
                                        </button>
                                    </div>
                                </div>


                                <div className={`${showMobileMenu === false ? 'hidden' : 'show'} justify-between items-center w-full h-full max-[1023px]:w-screen max-[1023px]:absolute max-[1023px]:left-0 max-[1023px]:top-[4.5rem] iej2uhHue max-[1023px]:backdrop-blur-sm max-[1023px]:bg-black/10 max-[1023px]:h-screen`}>
                                    <ul className={`flex flex-col h-full sm:mt-4 lg:flex-row lg:space-x-8 lg:mt-0 ${scrollPosition >= 2 ? "max-[1023px]:bg-zinc-950" : "max-[1023px]:bg-black"} h-min py-12`}>
                                        <li
                                            className={`n29 BAjd3u3bhue font-medium py-3 text-right h-100 uppercase cursor-pointer text-lg px-5 ${location.pathname === "/" || location.pathname === "/home" ? 'active' : ''}`}>
                                            <NavLink className={`p-2 bu83nVUw text-gray-600 w-full sm:inline block`} to={"/home"}>Home</NavLink>
                                        </li>

                                        {headerMiddenLinks.map((midden) => (
                                            <li key={midden.id + "b9k"} className={`n29 BAjd3u3bhue font-medium py-3 text-right h-100 uppercase cursor-pointer text-lg px-5 ${midden.link === location.pathname ? 'active' : ''}`}>
                                                <NavLink className={`p-2 bu83nVUw text-gray-600 w-full sm:inline block`} to={midden.link}>{midden.label}</NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                    {/* tablet navbar ends */}



                    {/* desktop navbar start */}
                    {width > 1023 && (
                        <div className="desktop-navbar h-full flex justify-between items-center w-full">
                            <Link to="/home" className=" flex items-center justify-center p-1 h-12 rounded-md -mt-2">
                                <ShaadowLogo width={"155"} height={"45"} type={"white"} />
                            </Link>


                            <div className="flex items-center lg:order-2 h-full lg:flex-row lg:space-x-8 lg:mt-0">
                                {/* notify-me/ download button */}
                                <button
                                    onClick={gotoNotifyPage}
                                    className="n29 text-gray-600 hover:sm:bg-zinc-800 hover:text-white font-medium rounded-lg cursor-pointer text-xs px-4 py-4 sm:py-2 sm:outline sm:outline-1 sm:outline-gray-500 hover:sm:outline-none uppercase ml-3 sm:ml-0 flex items-center justify-between">
                                    <span className="mr-0 sm:mr-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
                                        </svg>
                                    </span>
                                    <span className="">Get Notified</span>
                                </button>

                                {/* products button */}
                                <div className="vy8ggjAWfg relative h-full flex justify-center items-center">
                                    <button
                                        onClick={() => { setShowPrdct(!showPrdct) }}
                                        className={`n29 bh8vAwegFY86 text-white font-medium rounded-lg text-sm py-2.5 cursor-pointer text-center inline-flex items-center ${showPrdct && 'active'}`}
                                        type="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" className={`w-6 h-6 fill-gray-600`}><path d="M240-160q-33 0-56.5-23.5T160-240q0-33 23.5-56.5T240-320q33 0 56.5 23.5T320-240q0 33-23.5 56.5T240-160Zm240 0q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm240 0q-33 0-56.5-23.5T640-240q0-33 23.5-56.5T720-320q33 0 56.5 23.5T800-240q0 33-23.5 56.5T720-160ZM240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400ZM240-640q-33 0-56.5-23.5T160-720q0-33 23.5-56.5T240-800q33 0 56.5 23.5T320-720q0 33-23.5 56.5T240-640Zm240 0q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Zm240 0q-33 0-56.5-23.5T640-720q0-33 23.5-56.5T720-800q33 0 56.5 23.5T800-720q0 33-23.5 56.5T720-640Z" /></svg>
                                        <span className="ml-1 text-gray-600 uppercase hidden sm:block">PRODUCTS</span>
                                    </button>

                                    {/* <!-- Products Dropdown menu --> */}
                                    {showPrdct && (<>
                                        <div className="b77x5Ty sm:w-96 overflow-hidden sm:absolute sm:top-full sm:mt-1 sm:right-0 z-10 shadow sm:rounded-lg p-4 sm:border sm:border-solid sm:border-[#333] transition ease-in-out delay-150">
                                            <div className="w-full h-full sm:pt-0">
                                                <div className="grid grid-rows-3 grid-flow-col gap-4 w-full">
                                                    <div>
                                                        <div className="cursor-default grid gap-0 grid-cols-4 w-full border border-solid border-[#333] rounded-lg overflow-hidden b7hHuwjeH72">
                                                            <div className="flex justify-center items-center w-16 h-16 col-span-1 p-2 c6fAe5t overflow-hidden">
                                                                <img className="w-auto h-auto rounded-full" src={ShaadowOnlyLogoIcon.imgUrl} alt={ShaadowOnlyLogoIcon.imgAlt} />
                                                            </div>
                                                            <div className="flex items-start justify-center flex-col w-full h-full col-span-3 -ml-3">
                                                                <p className="mb-0 font-medium text-[#f9f9f9] n29 text-base leading-5">Shaadow App</p>
                                                                <p className="mb-0 font-normal text-[#f9f9f99d] n29 text-sm">Coming soon!</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Link to={"https://tunes.shaadow.in/?url_ref=header&url_source=shaadow.in"}>
                                                        <div className="cursor-pointer grid gap-0 grid-cols-4 w-full border border-solid border-[#333] rounded-lg overflow-hidden b7hHuwjeH72">
                                                            <div className="flex justify-center items-center w-16 h-16 col-span-1 p-2 c6fAe5t overflow-hidden">
                                                                <img className="w-auto h-auto rounded-full" src={tunesLogo.imgUrl} alt={tunesLogo.imgAlt} />
                                                            </div>
                                                            <div className="flex items-start justify-center flex-col w-full h-full col-span-3 -ml-3">
                                                                <p className="mb-0 font-medium text-[#f9f9f9] n29 text-base leading-5">Tunes By Shaadow</p>
                                                                <p className="mb-0 font-normal text-[#f9f9f99d] n29 text-sm">Your favorite music buddy!</p>
                                                            </div>
                                                        </div>
                                                    </Link>

                                                    <Link to={"https://hp.shaadow.in/?url_ref=header&url_source=shaadow.in"}>
                                                        <div className="cursor-pointer grid gap-0 grid-cols-4 w-full border border-solid border-[#333] rounded-lg overflow-hidden b7hHuwjeH72">
                                                            <div className="flex justify-center items-center w-16 h-16 col-span-1 p-2 c6fAe5t overflow-hidden">
                                                                <img className="w-auto h-auto rounded-full" src={homePageLogo.imgUrl} alt={homePageLogo.imgAlt} />
                                                            </div>
                                                            <div className="flex items-start justify-center flex-col w-full h-full col-span-3 -ml-3">
                                                                <p className="mb-0 font-medium text-[#f9f9f9] n29 text-base leading-5">Home-Page</p>
                                                                <p className="mb-0 font-normal text-[#f9f9f99d] n29 text-sm">A searching tool 4 browser!</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    {/* others */}
                                                </div>
                                            </div>
                                            <div
                                                className="max-[640px]:fixed max-[640px]:top-3 max-[640px]:left-0 max-[640px]:w-full max-[640px]:h-10 max-[640px]:flex max-[640px]:justify-between max-[640px]:items-center sm:hidden">
                                                <p className="text-base text-gray-100 mb-0 ml-6">Our Products</p>
                                                <button
                                                    className="w-10 h-10 flex justify-center items-center"
                                                    onClick={() => { setShowPrdct(!showPrdct) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#f9f9f9" className="w-6 h-6">
                                                        <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </>)}
                                </div>


                                {/* menu btn for tablet */}
                                <button
                                    type="button"
                                    onClick={handleMobileNavigation}
                                    className="inline-flex items-center p-2 text-sm text-gray-600 focus:text-white hover:text-white rounded-lg lg:hidden focus:outline-none">
                                    <span className="hidden">Open main menu</span>
                                    {showMobileMenu === false ? (
                                        <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                    ) : (
                                        <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    )}
                                </button>

                            </div>


                            <div className={`justify-between items-center w-full h-full lg:flex lg:w-auto iej2uhHue`}>
                                <ul className={`flex flex-col h-full sm:mt-4 lg:flex-row lg:space-x-8 lg:mt-0`}>
                                    <li
                                        className={`n29 BAjd3u3bhue font-medium sm:text-center text-right h-full uppercase cursor-pointer text-sm ${location.pathname === "/" || location.pathname === "/home" ? 'active' : ''}`}>
                                        <NavLink className={`p-2 h-full bu83nVUw text-gray-600 sm:w-fit w-full flex justify-center items-center`} to={"/home"}>Home</NavLink>
                                    </li>
                                    {headerMiddenLinks.map((midden) => (
                                        <li key={midden.id + "b9k"} className={`n29 BAjd3u3bhue font-medium sm:text-center text-right h-full uppercase cursor-pointer text-sm ${midden.link === location.pathname ? 'active' : ''}`}>
                                            <NavLink className={`p-2 h-full bu83nVUw text-gray-600 sm:w-fit w-full flex justify-center items-center`} to={midden.link}>{midden.label}</NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    {/* desktop navbar ends */}
                </div>
            </div>
        </header>
    )
}

export default HeaderComponent;