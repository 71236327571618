import React, { createContext, useState, useEffect } from "react";

const UserContext = createContext({
    user: null, // Initial state for user data
    setUser: () => { }, // Empty function for updating user
});

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        try {
            // Attempt to retrieve user data from localStorage (if available)
            const storedUser = localStorage.getItem("_sdw-user");
            if (storedUser) {
                return JSON.parse(storedUser); // Parse stored JSON data
            }
        } catch (error) {
            console.error("Error parsing stored user data:", error);
        }
        return null; // Return null if no valid user data is found
    });

    useEffect(() => {
        // Update localStorage with user data upon changes
        if (user) {
            try {
                localStorage.setItem("_sdw-user", JSON.stringify(user)); // Stringify data
            } catch (error) {
                console.error("Error saving user data to localStorage:", error);
            }
        }
    }, [user]); // Dependency on user state changes

    const updateUser = (userData) => {
        setUser({ ...user, ...userData }); // Merge new data with existing state
    };

    const logoutUser = () => {
        setUser(null); // Clear user data on logout
        localStorage.removeItem("_sdw-user"); // Remove data from localStorage
    };

    return (
        <UserContext.Provider
            value={{
                user,
                updateUser,
                logoutUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
