import { Link, useLocation } from 'react-router-dom';
import HeaderLinks from "../LocalDB/NavigationLinks.json";
import ShaadowLogo from './_shaadowLogo';
import { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';

const FooterComponent = () => {
    const location = useLocation();
    const year = new Date().getFullYear();


    const middenData = [10754, 10755, 10756, 10759, 10760, 10761];
    const lastlyData = [10759, 10760, 10761];

    const headerMiddenLinks = HeaderLinks.normal.filter((midden) =>
        middenData.includes(midden.id)
    );
    const headerLastlyLinks = HeaderLinks.legal.filter((lastly) =>
        lastlyData.includes(lastly.id)
    );

    const [subscribeMail, setSubscribeMail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [showSuccess, setShowSuccess] = useState("");
    const [showError, setShowError] = useState("");

    const currentDateTime = new Date().toISOString();


    function generateUniqueId() {
        const randomNumber = Math.random().toString().slice(2, 14); // Take 12 digits from a random decimal
        return randomNumber;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const uniqueId = generateUniqueId(); // Implement suitable unique ID generation logic

        // Combine data with current date/time and unique ID
        const storeVisitorContact = {
            slno: uniqueId,
            email: subscribeMail,
            createdAt: currentDateTime, // Use Firebase Timestamp for accurate server-side time
            ref: "SN-" + uniqueId
        };

        if (subscribeMail !== "" || !subscribeMail) {
            try {
                const docRef = await addDoc(collection(db, "subscribe_newstter_n93jw"), storeVisitorContact);

                if (docRef) {
                    console.log("email: " + subscribeMail);

                    setSubscribeMail("");

                    setShowSuccess(`Thanks for subscribing our newsletter`);
                } else {
                    setShowError("Something went wrong");
                }
            } catch (error) {
                setShowError("Error: " + error);
            }
        } else {
            setShowError("Email cannot be empty");
        }
        setIsLoading(false);

        setTimeout(() => {
            setShowError('');
            setShowSuccess('');
        }, 12000);
    };


    return (
        <>
            <section className="w-full flex justify-center items-center bg-[#0d1117] p-6 md:p-12">
                <div className="w-full flex justify-between items-center max-[640px]:flex-col p-6 md:p-12 rounded-lg border border-zinc-700">
                    <div className="md:w-3/5 w-full">
                        <p className="md:text-3xl max-[640px]:text-2xl font-semibold text-zinc-50 mb-4">Subscribe to your newsletter</p>
                        <p className="md:w-[90%] md:text-lg md:font-medium max-[640px]:text-base text-zinc-500">Stay updated on Shaadow App, launching details, new updates, Shaadow products, new product access etc</p>
                    </div>
                    <div className="md:w-2/5 w-full h-full  max-[640px]:mt-10">
                        <form className="w-full md:h-14">
                            <div className="relative w-full h-full md:bg-black text-white rounded-lg md:overflow-hidden md:border md:border-zinc-700">
                                <input
                                    className="md:absolute md:top-0 md:bottom-0 md:left-0 md:w-3/4 max-[640px]:w-full min-w-[16rem] md:h-full max-[640px]:border max-[640px]:bg-black max-[640px]:border-zinc-700 max-[640px]:h-14 text-white font-medium md:bg-transparent outline-none md:border-0 placeholder:text-zinc-500 px-3 text-base rounded-lg"
                                    type="email"
                                    autoComplete="off"
                                    placeholder="Your email address"
                                    value={subscribeMail}
                                    onChange={(e) => setSubscribeMail(e.target.value)}
                                />

                                {subscribeMail !== "" ? (<>
                                    <button
                                        onClick={handleSubmit}
                                        className="L93b893n md:absolute md:top-0 md:bottom-0 md:right-0 md:w-1/4 max-[640px]:w-full min-w-[6.5rem] md:h-full max-[640px]:h-14 max-[640px]:mt-4 bg-black max-[640px]:rounded-lg text-white hover:bg-zinc-950 hover:text-gray-100 text-base">
                                        {isLoading ? (<>
                                            Loading...
                                        </>) : (<>
                                            <span className="font-semibold tracking-[0.010rem] text-center">Subscribe</span>
                                        </>)}
                                    </button>
                                </>) : (<>
                                    <div className="cursor-not-allowed L93b893n md:absolute md:top-0 md:bottom-0 md:right-0 md:w-1/4 max-[640px]:w-full min-w-[6.5rem] md:h-full max-[640px]:h-14 max-[640px]:mt-4 bg-black max-[640px]:rounded-lg text-white hover:bg-zinc-950 hover:text-gray-100 text-base flex justify-center items-center">
                                        <span className="font-semibold tracking-[0.010rem] text-center">Subscribe</span>
                                    </div>
                                </>)}
                            </div>
                        </form>
                        {showError && (<>
                            <pre>
                                <div className="mt-6 text-white text-right bg-red-500 rounded-md">
                                    <p className="text-lg leading-6 text-white px-4 py-2 max-w-fit text-wrap">{showError}</p>
                                </div>
                            </pre>
                        </>)}
                        {showSuccess && (<>
                            <div className="mt-6 text-white text-right bg-green-500 rounded-md">
                                <p className="text-lg leading-6 text-white px-4 py-2 max-w-fit text-wrap">{showSuccess}</p>
                            </div>
                        </>)}
                    </div>
                </div>
            </section>



            <footer className="bg-black shadow">
                <div className="w-full px-6 sm:px-20 py-8">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <div className="flex items-center p-1 h-12 rounded-md cursor-default -mt-2">
                            <ShaadowLogo width={"150"} height={"60"} type={"white"} />
                        </div>


                        <ul className="flex flex-wrap sm:items-center max-[768px]:w-full max-[768px]:flex-col mb-6 max-[768px]:mt-8 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                            {headerMiddenLinks.map((midden) => (
                                <li key={midden.id + "b9k"}
                                    className={`${midden.link === location.pathname ? 'text-gray-400' : ''} ZZZby738eheye py-2`}>
                                    <Link
                                        onClick={() => window.scrollTo(0, 0)}
                                        className={`hover:underline me-4 md:me-6`}
                                        to={midden.link}>
                                        {midden.label}
                                    </Link>
                                </li>
                            ))}

                            {headerLastlyLinks.map((lastly) => (
                                <li key={lastly.id + "b9k"}
                                    className={`${lastly.link === location.pathname ? 'text-gray-300' : ''} ZZZby738eheye py-2`}>
                                    <Link
                                        onClick={() => window.scrollTo(0, 0)}
                                        className={`hover:underline me-4 md:me-6`}
                                        to={lastly.link}>
                                        {lastly.label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
                    <div className="flex sm:justify-between justify-center items-center max-[768px]:flex-col">
                        <p className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© {year}, Shaadow Platforms</p>

                        <div className="max-[768px]:mt-4">
                            <div className="flex items-center justify-center">
                                <a href="http://instagram.com/shaadowapp" target="_blank" rel="noreferrer" className="p-1 mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="fill-gray-400 hover:fill-white instagram" viewBox="0 0 16 16">
                                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                    </svg>
                                </a>

                                <a href="http://threads.net/@shaadowapp" target="_blank" rel="noreferrer" className="p-1 mx-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="fill-gray-400 hover:fill-white threads" viewBox="0 0 16 16">
                                        <path d="M6.321 6.016c-.27-.18-1.166-.802-1.166-.802.756-1.081 1.753-1.502 3.132-1.502.975 0 1.803.327 2.394.948s.928 1.509 1.005 2.644q.492.207.905.484c1.109.745 1.719 1.86 1.719 3.137 0 2.716-2.226 5.075-6.256 5.075C4.594 16 1 13.987 1 7.994 1 2.034 4.482 0 8.044 0 9.69 0 13.55.243 15 5.036l-1.36.353C12.516 1.974 10.163 1.43 8.006 1.43c-3.565 0-5.582 2.171-5.582 6.79 0 4.143 2.254 6.343 5.63 6.343 2.777 0 4.847-1.443 4.847-3.556 0-1.438-1.208-2.127-1.27-2.127-.236 1.234-.868 3.31-3.644 3.31-1.618 0-3.013-1.118-3.013-2.582 0-2.09 1.984-2.847 3.55-2.847.586 0 1.294.04 1.663.114 0-.637-.54-1.728-1.9-1.728-1.25 0-1.566.405-1.967.868ZM8.716 8.19c-2.04 0-2.304.87-2.304 1.416 0 .878 1.043 1.168 1.6 1.168 1.02 0 2.067-.282 2.232-2.423a6.2 6.2 0 0 0-1.528-.161" />
                                    </svg>
                                </a>

                                <a href="http://twitter.com/shaadowapp" target="_blank" rel="noreferrer" className="p-1 mx-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="fill-gray-400 hover:fill-white twitter-x" viewBox="0 0 16 16">
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                    </svg>
                                </a>

                                <a href="http://linkedin.com/company/shaadowapp" target="_blank" rel="noreferrer" className="p-1 ml-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="fill-gray-400 hover:fill-white linkedin" viewBox="0 0 448 512">
                                        <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default FooterComponent;